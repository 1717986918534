import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainContent, PageWrapper } from "./components/StyledComponents";
import Navbar from "./components/NavigationBar_tw.js";
import Home from "./components/Home_Element.js";
import Courses from "./components/Courses";
import Team from "./components/Team";
import Info from "./components/Info";
import Contact from "./components/Contact";
import Quiz from "./components/Quiz.js";
import QuizCarousel from "./components/QuizCarousel.js";
import Footer from "./components/Footer.js";
import Datenschutz from "./components/Datenschutz.js";
import Impressum from "./components/Impressum.js";
import FAQ from "./components/FAQ.js"
import "bootstrap/dist/css/bootstrap.min.css";
import CourseById from "./components/CourseById.js";
import { UserProvider } from "./context/UserContext.js";
import UserTypeButton from "./components/UserTypeButton.js";
import CookieConsent from './components/CookieConsent';  // Import the CookieConsent component




function App() {
  return (
    <UserProvider>
      <PageWrapper>
        <Router>
          <Navbar />
          <UserTypeButton />
          <MainContent>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:id" element={<CourseById />} />
              <Route path="/team" element={<Team />} />
              <Route path="/info" element={<Info />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/quizCarousel" element={<QuizCarousel />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/faq" element={<FAQ />} />
            </Routes>
          </MainContent>
          <CookieConsent />
          <Footer />
        </Router>
      </PageWrapper>
    </UserProvider>
  );
}

export default App;
