// src/UserContext.js

import React, { createContext, useState } from 'react';

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  // Initialize the isParent state to true by default
  const [isParent, setIsParent] = useState(true);

  return (
    <UserContext.Provider value={{ isParent, setIsParent }}>
      {children}
    </UserContext.Provider>
  );
};
