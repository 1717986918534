// src/components/UserTypeButton.js

import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../context/UserContext";
import kidIcon from "../icons/kid_icon.png";
import parentIcon from "../icons/parent_icon.png";
import "./UserTypeButton.css"; // Import custom styles for the speech bubble

const UserTypeButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isParent, setIsParent } = useContext(UserContext);
  const dropdownRef = useRef();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setIsDropdownOpen(false);

  const handleUserSelect = (type) => {
    setIsParent(type === "parent");
    closeDropdown();
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed top-[1%] left-[5%] md:top-[14%] md:left-[4%] z-50" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-center w-16 h-16 bg-custom_tertiary text-white rounded-full shadow-lg hover:bg-green-700 transition duration-200 focus:outline-none"
        aria-haspopup="true"
        aria-expanded={isDropdownOpen}
      >
        <img 
          src={isParent ? parentIcon : kidIcon} 
          alt={isParent ? "Parent" : "Child"}
          className="w-10 h-10 object-contain"
        />
      </button>

      {isDropdownOpen && (
        <div
          className="speech-bubble absolute mt-2 w-40 rounded-md shadow-lg bg-custom_white ring-1 ring-black ring-opacity-5
          left-[60%] -translate-x-3/4 sm:left-[45%] sm:-translate-x-1/2"
        >
          <div
            className="py-1 no-padding"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-type-button"
          >
            <button
              onClick={() => handleUserSelect("parent")}
              className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary transition duration-200 text-custom_neutral hover:text-custom_white rounded-t-md"
              role="menuitem"
            >
              Elternteil
            </button>
            <button
              onClick={() => handleUserSelect("child")}
              className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary transition duration-200 text-custom_neutral hover:text-custom_white rounded-b-md"
              role="menuitem"
            >
              Kind
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTypeButton;
