import React from 'react';
import { 
  MainContent, 
  HeaderWrapper, 
  ContentWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title } 
  from "./StyledComponents";

function Impressum() {
  return (
    <MainContent>
      <HeaderContainer> 
        <HeaderWrapper>
          <Title>Impressum</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <ContentWrapper>
          <p>Lucas Fortune<br />
            Kreuzstra&szlig;e 16<br />
            04103 Leipzig</p>
          <h2>Kontakt</h2>
          <p>Telefon: &#91;Telefonnummer&#93;<br />
            E-Mail: info@codestart.com</p>
          <h2>EU-Streitschlichtung</h2>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2> 
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </ContentWrapper>
      </ContentContainer>
    </MainContent>
  );
}

export default Impressum;
