import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
  height: 100vh;
}
`;

export const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow: hidden;
  padding: 40px 0px 10px 0px;
  background-image: linear-gradient(to bottom right, white, rgb(255, 198, 54));
`;

export const HeaderContainer = styled.div`
  padding: 20px;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;

`;

export const ContentContainer = styled.div`
  flex: 1;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  overflow: hidden; // Hide overflow on the outer container
  box-shadow: 2px 2px 4px rgba(0,0,0,.1),
            -2px -2px 4px rgba(0,0,0,.1);
  @media (max-width: 768px) {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    box-shadow: none;
    width: 90%
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  padding: 30px;

  overflow-y: auto;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }

  @media (max-width: 768px) {
    padding: 10px; // Smaller padding on smaller screens
  }
`;

export const HeaderWrapper = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const Title = styled.h1`
  color: rgb(90, 90, 90);
`;

export const Subtitle = styled.h1`
  text-align: Center;
  color: rgb(90, 90, 90);
`;

export const Subcontent = styled.div`
  margin: 0;
`;

export const Divide = styled.hr`
  border: 2px solid black;
  margin-bottom: 100px
`;

export const Text = styled.p`
  color: rgb(90, 90, 90);
  font-size: large;
@media (max-width: 768px) {
  letter-spacing: 1px;
  }
@media (min-width: 768px) {
    line-height: 40px;
    letter-spacing: 2px;
  }
@media (min-width: 1198px) {
    font-size: x-large
  }

`;


export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #e6ffec;
  }
`;

export const EmailLink = styled.a`
  color: rgb(60, 60, 60);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
