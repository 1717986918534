import React from "react";
import { 
  MainContent, 
  HeaderWrapper, 
  ContentWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title,
  Divide } 
  from "./StyledComponents";
import Team from "./Team";
import OurVision from "./OurVision";

const Info = () => (
  <MainContent> 
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Informationen</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer >
        <ContentWrapper>
          <OurVision/>
          <Divide/>
          <Team/>
        </ContentWrapper>
      </ContentContainer>
  </MainContent>
);

export default Info;
