import React from 'react';
import { useContext } from 'react';
import visionData from "../data/vision.json";
import { Subtitle, Subcontent } from "./StyledComponents";
import styles from "./OurVision.module.css";
import { UserContext } from "../context/UserContext";


const GetDescriptionFromPoint = ({point}) => {
  const { isParent } = useContext(UserContext);
  
  if (isParent) {
    console.log("getDescription parent");
    if (typeof point.description === 'object' && point.description.parts) {
      return (
        <p>
          {point.description.parts.map((part, index) => (
            part.type === 'bold' 
              ? <strong key={index}>{part.text}</strong>
              : <span key={index}>{part.text}</span>
          ))}
        </p>
      );
    }
    return <p>{point.description}</p>;
  } else {
    console.log("getDescription no parent");
    return <p>{point.description_kind}</p>;
  }
}

const VisionPoint = ({ point, index }) => {
  const [imgSrc, setImgSrc] = React.useState(point.iconUrl);

  const handleImageError = () => {
    console.error(`Failed to load image: ${imgSrc}`);
    setImgSrc("./fallback-image.jpg"); // Provide a fallback .webp image
  };
  const isReversed = index % 2 !== 0;
  return (

    <div className={`${styles.visionPoint} ${isReversed ? styles.reverse : ''}`}>
      <div className={styles.iconContainer}>
        <img
          src={imgSrc}
          alt={point.title}
          className={styles.icon}
          onError={handleImageError}
        />
      </div>
      <div className={styles.info}>
        <h2 className={styles.pointTitle}>{point.title}</h2>
        <div className={styles.description}>
          {GetDescriptionFromPoint({point})}
        </div>
      </div>
    </div>

  );
};

const OurVision = () => (
  <Subcontent>
    <Subtitle>Über die Academy</Subtitle>
    <div className={styles.visionContainer}  style={{ paddingBottom: "30px" }}>
      {visionData.map((point, index) => (
        <VisionPoint key={index} point={point} index={index} />
      ))}
    </div>
  </Subcontent>
);

export default OurVision;
