import React, { useRef, useState, useEffect } from 'react';
import {
  MainContent,
  HeaderWrapper,
  ContentWrapper,
  ContentContainer,
  HeaderContainer,
  Title,
  EmailLink,
} from "./StyledComponents";
import styles from "./Contact.module.css";
import sendEmail from "./EmailSender";
import { Smile, Frown, HelpCircle } from 'lucide-react';

function Contact() {
  const form = useRef();
  const [showInquiry, setShowInquiry] = useState(false);
  const [quizDone, setQuizDone] = useState(false);
  const [timeSlotTooltip, setTimeSlotTooltip] = useState(false);
  const [courseLength, setCourseLength] = useState('2');
  const [courseLengthTooltip, setCourseLengthTooltip] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ 
    submitted: false, 
    success: false,
    error: false
  });



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Starting submission");
  
    const formData = new FormData(form.current);
    const from_name =
      formData.get("first_name") + " " + formData.get("last_name");
    const reply_to = formData.get("email");
    const message = formData.get("message");
    const salutation = formData.get("salutation");
    const phone = formData.get("phone");
  
    // Base template parameters
    let templateParams = {
      from_name,
      reply_to,
      salutation,
      first_name: formData.get("first_name"),
      last_name: formData.get("last_name"),
      email: formData.get("email"),
      phone,
      message,
      showInquiry: showInquiry ? 'Ja' : 'Nein',
    };
  
    if (showInquiry) {
      const quizDoneValue = quizDone ? 'Ja' : 'Nein';
      const courseName = formData.get("courseName");
      const preferredStartDate = formData.get("preferredStartDate");
      const childName = formData.get("childName");
      const childAge = formData.get("childAge");
      const childGender = formData.get("childGender");
  
      // Preferred weekly times
      const timeSlots = ['mo1', 'tue1', 'wed1', 'thu1', 'fr1', 'mo2', 'tue2', 'wed2', 'thu2', 'fr2'];
      const preferredTimes = {};
      timeSlots.forEach(slot => {
        preferredTimes[slot] = formData.get(slot) ? 'Ja' : 'Nein';
      });
      const courseLength = formData.get("courseLength");
  
      templateParams = {
        ...templateParams,
        courseLength,
        quizDone: quizDoneValue,
        courseName,
        preferredStartDate,
        childName,
        childAge,
        childGender,
        ...preferredTimes,
      };
    }
  
    try {
      setSubmitStatus({ submitted: true, success: false, error: false }); // Initial submission
      await sendEmail(templateParams);
      setSubmitStatus({ submitted: true, success: true, error: false }); // Success
      
      // Reset form after successful submission
      form.current.reset();
      setShowInquiry(false);
      setQuizDone(false);
      setCourseLength('2');
      
      // Reset message after 5 seconds
      setTimeout(() => {
        setSubmitStatus({ submitted: false, success: false, error: false });
      }, 5000);
  } catch (error) {
      console.log("FAILED...", error.text);
      setSubmitStatus({ submitted: true, success: false, error: true }); // Error state
  }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (timeSlotTooltip || courseLengthTooltip) {
        setTimeSlotTooltip(false);
        setCourseLengthTooltip(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [timeSlotTooltip, courseLengthTooltip]);

  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Kontakt</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <ContentWrapper>
          <form ref={form} onSubmit={handleSubmit} className={styles.Form}>
            <div className={styles.Row}>
              <div className={styles.Field}>
                <label htmlFor="sal">Anrede</label>
                <select
                  className={styles.Input}
                  id="sal"
                  name="salutation"
                >
                  <option value="none"> - </option>
                  <option value="Frau">Frau</option>
                  <option value="Herr">Herr</option>
                </select>
              </div>
              <div className={styles.Field}>
                <label htmlFor="vorname">Vorname*</label>
                <input
                  type="text"
                  name="first_name"
                  id="vorname"
                  placeholder="Vorname"
                  className={styles.Input}
                  required
                />
              </div>
              <div className={styles.Field}>
                <label htmlFor="nachname">Nachname*</label>
                <input
                  type="text"
                  name="last_name"
                  id="nachname"
                  placeholder="Nachname"
                  className={styles.Input}
                  required
                />
              </div>
            </div>
            <div className={styles.Row}>
              <div className={styles.Field}>
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className={styles.Input}
                  required
                />
              </div>
              <div className={styles.Field}>
                <label htmlFor="phone">Telefon</label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Telefon"
                  className={styles.Input}
                />
              </div>
            </div>
            <div className={styles.CheckboxField}>
              <input
                type="checkbox"
                id="showInquiry"
                checked={showInquiry}
                onChange={() => setShowInquiry(!showInquiry)}
              />
              <label htmlFor="showInquiry" className='pt-1.5'>Direkt Kursanfrage mitsenden</label>
            </div>

            {showInquiry && (
              <div className={styles.InquirySection}>
                <p className='text-justify'>Wenn du direkt eine Anfrage für einen unserer Programmierkurse mitsenden möchtest, brauchen wir noch etwas zusätzliche Information. Du bekommst dann von uns ein Angebot mit verfügbaren Kursen, die zu deiner Anfrage passen, sowie allen weiteren Infos.</p>
                <div className={styles.CheckboxField}>
                  <label htmlFor="showHappy" className={styles.Happy_label}>
                    <a href="/quizcarousel" className={styles.Quiz_link}>Einstufungsquiz</a> schon gemacht?
                  </label>
                  <input 
                    type="checkbox" 
                    id="showHappy"
                    checked={quizDone}
                    onChange={(e) => setQuizDone(e.target.checked)}
                  />
                  <span className={styles.QuizStatus}>
                    {quizDone ? (
                      <>
                        <span className={styles.QuizStatusText}>Ja!</span>
                        <Smile className={styles.Smiley} color="#4a7665"/>
                      </>
                    ) : (
                      <>
                        <span className={styles.QuizStatusText}>Nein</span>
                        <Frown className={styles.Smiley} color="#d32f2f"/>
                      </>
                    )}
                  </span>
                </div>
                <div className={styles.Row}>
                  <div className={styles.Field}>
                    <label htmlFor="courseName">Gewünschter Kurs*</label>
                    <select className={styles.Input} id="courseName" name="courseName" required>
                      <option value="CSG1">CS Grundlagen</option>
                      <option value="CSW2">CS Webdesign</option>
                      <option value="CSS2">CS Spieldesign</option>
                      <option value="CSW3">CS Webdesign 2</option>
                      <option value="CSA3">CS Appdesign</option>
                      <option value="CSP3">CS Python</option>
                      <option value="CSS3">CS Spieldesign 2</option>
                    </select>
                  </div>
                  <div className={styles.Field}>
                    <label htmlFor="preferredStartDate">Bevorzugtes Startdatum*</label>
                    <input 
                      type="date" 
                      name="preferredStartDate" 
                      id="preferredStartDate" 
                      className={styles.Input}
                      placeholder="TT.MM.JJJ"
                      required
                    />
                  </div>
                </div>
                <div className={styles.Row} name="additionalInfo">
                  <div className={styles.Field}>
                    <label htmlFor="childAge">Mein Kind ist</label>
                    <input 
                      type="number" 
                      id="childAge" 
                      name="childAge" 
                      min="6" 
                      max="18" 
                      className={styles.Input} 
                      placeholder="Alter*"
                      required
                    />
                    <label htmlFor="childGender"> Jahre alt, </label> 
                    <select className={styles.Input} id="childGender" name="childGender" >
                      <option value="weiblich">weiblich</option>
                      <option value="männlich">männlich</option>
                      <option value="other">(möchte ich nicht angeben)</option>
                    </select>
                    <label htmlFor="childName"> und heißt: </label>
                    <input type="text" name="childName" id="childName" placeholder="Name*" className={styles.Input} required/>
                  </div>
                  {/* Time slot table field */}
                  <div className={styles.Field}>
                    <div className="flex items-center gap-2">  {/* Removed mb-2 */}
                      <label>Bevorzugter wöchentlicher Termin</label>
                      <div className="relative inline-block">
                      <HelpCircle 
                          className="h-4 w-4 text-gray-500 cursor-help inline-block"
                          onMouseEnter={() => setTimeSlotTooltip(true)}
                          onMouseLeave={() => setTimeSlotTooltip(false)}
                          onClick={(e) => {
                            e.stopPropagation();
                            setTimeSlotTooltip(!timeSlotTooltip);
                          }}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            setTimeSlotTooltip(!timeSlotTooltip);
                          }}
                        />
                        <div 
                          className={`
                            absolute z-100
                            md:bottom-full md:left-1/2 md:transform md:-translate-x-1/2 md:mb-2
                            bottom-auto top-full right-0 mt-2
                            px-3 py-2 bg-gray-800 text-white text-sm rounded-lg 
                            transition-all duration-200 
                            w-48 h-52 text-center whitespace-normal
                            ${timeSlotTooltip ? 'opacity-100 visible' : 'opacity-0 invisible'}
                          `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          Mache hier an jedem vorgeschlagenen Termin, der passen würde, ein Häckchen. Sollte keiner davon passen, dann schreib uns gerne einfach in das Nachrichtenfeld, wann dein Kind Zeit hat.
                        </div>
                      </div>
                    </div>
                    <div className="border border-black rounded-md border-collapse mb-2">
                      <table className="w-full border-collapse bg-custom_white rounded-lg overflow-hidden">
                        <tr>
                          <th className="w-1/6 p-2 text-center"></th>
                          <th className="border-x border-black w-1/6 p-2 text-center">Mo</th>
                          <th className="border-x border-black w-1/6 p-2 text-center">Di</th>
                          <th className="border-x border-black w-1/6 p-2 text-center">Mi</th>
                          <th className="border-x border-black w-1/6 p-2 text-center">Do</th>
                          <th className="border-l border-black w-1/6 p-2 text-center">Fr</th>
                        </tr>
                        <tr>
                          <td className="border-y border-black p-2 text-sm">15:15 Uhr</td>
                          <td className="border border-black p-2 text-center"><input type='checkbox' id='mo1' name='mo1' /></td>
                          <td className="border border-black p-2 text-center"><input type='checkbox' id='tue1' name='tue1' /></td>
                          <td className="border border-black p-2 text-center"><input type='checkbox' id='wed1' name='wed1' /></td>
                          <td className="border border-black p-2 text-center"><input type='checkbox' id='thu1' name='thu1' /></td>
                          <td className="border-y border-black p-2 text-center"><input type='checkbox' id='fr1' name='fr1' /></td>
                        </tr>
                        <tr>
                          <td className="p-2 text-sm">17:45 Uhr</td>
                          <td className="border-x border-black p-2 text-center"><input type='checkbox' id='mo2' name='mo2' /></td>
                          <td className="border-x border-black p-2 text-center"><input type='checkbox' id='tue2' name='tue2' /></td>
                          <td className="border-x border-black p-2 text-center"><input type='checkbox' id='wed2' name='wed2' /></td>
                          <td className="border-x border-black p-2 text-center"><input type='checkbox' id='thu2' name='thu2' /></td>
                          <td className="border-l border-black p-2 text-center"><input type='checkbox' id='fr2' name='fr2' /></td>
                        </tr>
                      </table>
                    </div>
                    {/* Course length field */}
                    <div className={styles.Field}>
                      <div className="flex items-center gap-2">
                        <label htmlFor="courseLength">Kurslänge</label>
                        <div className="relative inline-block">
                        <HelpCircle 
                              className="h-4 w-4 text-gray-500 cursor-help inline-block"
                              onMouseEnter={() => setCourseLengthTooltip(true)}
                              onMouseLeave={() => setCourseLengthTooltip(false)}
                              onClick={(e) => {
                                e.preventDefault(); // Add this
                                e.stopPropagation();
                                setCourseLengthTooltip(!courseLengthTooltip);
                              }}
                              onTouchStart={(e) => {
                                e.preventDefault(); // Add this
                                e.stopPropagation();
                                setCourseLengthTooltip(!courseLengthTooltip);
                              }}
                              onTouchEnd={(e) => {  // Add this handler
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                          <div 
                            className={`
                              absolute z-100
                              md:bottom-full md:left-1/2 md:transform md:-translate-x-1/2 md:mb-2
                              bottom-full top-full -right-32 mt-2
                              px-3 py-2 bg-gray-800 text-white text-sm rounded-lg 
                              transition-all duration-200 
                              w-64 h-28 text-center whitespace-normal
                              ${courseLengthTooltip ? 'opacity-100 visible' : 'opacity-0 invisible'}
                            `}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Eine Unterrichtseinheit dauert 45 Minuten. Zwei Unterrichtseinheiten sind also 90 Minuten, drei sind 135 Minuten.
                          </div>
                        </div>
                      </div>
                      <select 
                        className={`${styles.Input}`}
                        id="courseLength" 
                        name="courseLength"
                        required
                        value={courseLength}
                        onChange={(e) => setCourseLength(e.target.value)}
                      >
                        <option value="2">2 Unterrichtseinheiten</option>
                        <option value="3">3 Unterrichtseinheiten</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <label htmlFor="mess">Nachricht*</label>
            <textarea name="message" id="mess" placeholder="Nachricht" className={styles.Message} required></textarea>
            <div className={styles.Datenschutz}> 
              <input type='checkbox' id='datenschutz' required/>
              <label htmlFor='datenschutz'><a href='/Datenschutz' target="_blank" rel="noreferrer noopener"> Datenschutzbestimmungen</a> zustimmen</label>
            </div>  

            <div className="relative">
  <button 
    className={`${styles.Send} relative`} 
    type="submit"
    disabled={submitStatus.submitted}
  >
    Absenden
  </button>
  
  {submitStatus.submitted && (submitStatus.success || submitStatus.error) && (
    <div 
      className={`
        absolute -top-12 left-0 p-3 rounded-lg text-sm font-medium
        transition-all duration-300
        ${submitStatus.success 
          ? 'bg-green-100 text-green-800 border border-green-400' 
          : 'bg-red-100 text-red-800 border border-red-400'
        }
      `}
    >
      {submitStatus.success 
        ? "Nachricht erfolgreich gesendet!" 
        : submitStatus.error
        ? "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
        : null
      }
    </div>
  )}
</div>
          </form>
          <label>
            oder senden Sie uns eine E-Mail direkt an:{" "}
            <EmailLink href="mailto:info@codestart.com">
              info@codestart.com
            </EmailLink>
          </label>
        </ContentWrapper>
      </ContentContainer>
    </MainContent>
  );
}

export default Contact;
