import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import DOMPurify from 'dompurify';
import faqData from '../data/faq.json';
import { 
  MainContent, 
  HeaderWrapper, 
  ContentWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title}
  from "./StyledComponents"; 
import styles from './faq.module.css';

const sanitizeHtml = (html) => {
  const clean = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['a'],
    ALLOWED_ATTR: ['href', 'target']
  });
  return clean;
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.faqItem}>
      <button
        className={styles.faqQuestion}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <p 
          className={styles.faqAnswer}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(answer) }}
        />
      )}
    </div>
  );
};

const FAQ = () => {
  return (
    <MainContent> 
      <HeaderContainer>
        <HeaderWrapper>
          <Title>FAQs</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer >
        <ContentWrapper>
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}

        </ContentWrapper>
      </ContentContainer>
  </MainContent>

  );
};

export default FAQ;