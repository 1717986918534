import React from 'react';
import { useContext } from 'react';
import teamData from '../data/team.json';
import { Subtitle, Subcontent } from "./StyledComponents";
import styles from './Team.module.css';
import { UserContext } from '../context/UserContext';

const GetBioFromPoint = ({point}) => {
  const { isParent } = useContext(UserContext);
  if (isParent) {
    return <p>{point.bio}</p>
  } else {
    return <p>{point.bio_kind}</p>
  }
}

const TeamCard = ({ point , index}) => {
  const [imgSrc, setImgSrc] = React.useState(point.photoUrl);

  const handleImageError = () => {
    console.error(`Failed to load image: ${imgSrc}`);
    setImgSrc("./fallback-image.jpg"); // Provide a fallback .webp image
  };
  const isReversed = index % 2 !== 0;
  return (

    <div className={`${styles.visionPoint} ${isReversed ? styles.reverse : ''}`}>
      <div className={styles.iconContainer}>
        <img
          src={imgSrc}
          alt={point.fullName}
          className={styles.icon}
          onError={handleImageError}
        />
      </div>
      <div className={styles.info}>
        <h2 className={styles.pointTitle}>{point.fullName}</h2>
        <h5>{point.title}</h5>
        <div className={styles.description}>
          {GetBioFromPoint({point})}
        </div>
      </div>
    </div>

  );
};

const Team = () => (
  <Subcontent>
    <Subtitle>Über das Team</Subtitle>
    <div className={styles.visionContainer}  style={{ paddingBottom: "30px" }}>
      {teamData.map((point, index) => (
        <TeamCard key={index} point={point} index={index}  />
      ))}
    </div>
  </Subcontent>
);

export default Team;