// EmailSender.js
import emailjs from "@emailjs/browser";

const sendEmail = (templateParams) => {
  // Retrieve the stored answer summary from sessionStorage
  const storedData = sessionStorage.getItem("answerSummary");
  const answerSummary = storedData ? JSON.parse(storedData) : { score: 0, summary: [] };

  const mergedParams = {
    ...templateParams,
    score: answerSummary.score, // Include score from answerSummary if available
    detailed_message: JSON.stringify(answerSummary.summary, null, 2) // Detailed message from answerSummary
  };


  return emailjs
    .send("service_avdp053", "template_zxwm9x6", mergedParams, {
      publicKey: "-47DGh2xGEPDUJqFs",
    })
    .then((response) => {
      console.log("Email sent successfully!", response.status, response.text);
      //onSuccess('Email sent successfully!');
      //return response;
    })
    .catch((error) => {
      console.error("Failed to send email:", error);
      //onError('Failed to send email.');
      //return error;
    });
};

export default sendEmail;
