import React from 'react';
import styles from './Footer.module.css'; // Import CSS for styling

function Footer() {
  const year = new Date().getFullYear(); // Current year for the copyright notice

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>

        <ul className={styles.footer_links}>
          <li className="px-10 py-2 bg-custom_tertiary font-semibold rounded-full shadow-md"><a href="/contact" className={styles.contact_link}>Kontakt</a></li >
          <li><a href="/faq">FAQs</a></li>
          <li><a href="/impressum">Impressum</a></li>
          <li><a href="/datenschutz">Datenschutzerklärung</a></li>
        </ul>
        <ul className={styles.footer_links}>
          <li>© {year} CodeStart. All rights reserved. </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;